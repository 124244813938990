import { AlarmsViews } from "..";
import { AlarmsTableData } from "../api/useGetAlarmsTableData";

export const filterAlarmsDetailData = ({
  alarmData,
  alarmsViews,
}: {
  alarmData: AlarmsTableData[];
  alarmsViews: AlarmsViews;
}): AlarmsTableData[] => {
  return alarmData.filter((alarm) => {
    const isBlocking =
      alarmsViews.blocking && !(alarm.Family === 200 || alarm.Family === 201);
    const isWarning = alarmsViews.warning && alarm.Family === 200;
    const isSignalings = alarmsViews.signalings && alarm.Family === 201;

    // Include l'allarme se soddisfa almeno una delle condizioni attive
    return isBlocking || isWarning || isSignalings;
  });
};
