import {
  DataGridPremium,
  GridColDef,
  GridToolbar,
  enUS,
  itIT,
  esES,
  deDE,
} from "@mui/x-data-grid-premium";
import { useTranslate, useLanguage, Translations } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { ExportData } from "../api/getExportData";

export function ExportTable({ exportData }: { exportData: ExportData }) {
  const translate = useTranslate();
  const language = useLanguage();
  const { formatTimestamp } = useFormatTimestamp();

  const getHeaderName = (key: string) => {
    const headerMappings: Record<string, string> = {
      state: "state",
      "alarm code": "alarms.alarm_code",
      imputation: "stop_imputation.imputation",
      "alarm is active": "alarms.active_alarm",
    };

    const translationKey = headerMappings[key];
    return translationKey
      ? translate(translationKey as Translations)
      : (key.charAt(0).toUpperCase() + key.slice(1)).replaceAll("_", " ");
  };

  const nCols = Object.keys(exportData[0]).length;

  const columns: GridColDef[] = [
    {
      field: "timestamp",
      headerName: translate("date"),
      type: "dateTime",
      valueFormatter: ({ value }) => {
        return (
          value &&
          formatTimestamp({
            timestamp: +new Date(value),
            skipTimezoneTransform: true,
          })
        );
      },
      valueGetter: ({ value }) => {
        const date =
          value &&
          formatTimestamp({
            timestamp: +new Date(value),
            skipLocale: true,
          });
        return value ? new Date(date) : null;
      },
      width: 200,
    },
    ...Object.keys(exportData[0])
      .filter((key) => key !== "timestamp")
      .map((key) => ({
        field: key,
        headerName: getHeaderName(key),
        width: nCols < 5 ? 250 : 150,
      })),
  ];

  columns.sort((a, b) => {
    if (a.field === "timestamp") return -1; // "timestamp" sempre primo
    if (b.field === "timestamp") return 1;

    const regex = /(\D+)(\d+)/; // Separare parte alfabetica e numerica
    const matchA = a.headerName
      ? a.headerName.match(regex)
      : a.field.match(regex);
    const matchB = b.headerName
      ? b.headerName.match(regex)
      : b.field.match(regex);

    if (matchA && matchB) {
      // Ordina per parte alfabetica
      const alphaCompare = matchA[1].localeCompare(matchB[1]);
      if (alphaCompare !== 0) return alphaCompare;

      // Ordina numericamente se ci sono numeri
      const numA = matchA[2] ? parseInt(matchA[2], 10) : 0;
      const numB = matchB[2] ? parseInt(matchB[2], 10) : 0;
      return numA - numB;
    }

    // Ordina alfabeticamente se non ci sono numeri
    return a.headerName
      ? a.headerName.localeCompare(b.headerName ? b.headerName : b.field)
      : a.field.localeCompare(b.headerName ? b.headerName : b.field);
  });

  const rows = exportData.map((row, index) => ({
    id: index,
    ...row,
  }));

  return (
    <Card>
      <DataGridPremium
        localeText={
          language === "en"
            ? enUS.components.MuiDataGrid.defaultProps.localeText
            : language === "it"
            ? itIT.components.MuiDataGrid.defaultProps.localeText
            : language === "es"
            ? esES.components.MuiDataGrid.defaultProps.localeText
            : language === "de"
            ? deDE.components.MuiDataGrid.defaultProps.localeText
            : enUS.components.MuiDataGrid.defaultProps.localeText
        }
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            excelOptions: {
              fileName: "Sentinel-Download",
            },
            csvOptions: {
              fileName: "Sentinel-Download",
            },
          },
        }}
        columns={columns}
        rows={rows}
        pagination={true}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 25, 50]} // Numero di elementi per pagina
      />
    </Card>
  );
}
