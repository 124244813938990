import { BasicTable } from "@/components/BasicTable";
import { Card } from "@/components/Layout/Card";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import { Stack, Typography } from "@mui/material";
import { getReportData } from "./utils/display-report";
import { getDataForDownload } from "./utils/download-report";
import { useGetReportsList } from "./api/useGetReportsList";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { OverviewNavBar } from "@/pages/Overview/layout/OverviewNavBar/OverviewNavBar";
import { useTranslate } from "@/i18n/config";
import { DateTimeRangePicker } from "@/components/Calendars/DateTimeRangePicker";
import { useTimeSelection } from "@/store/useTimeSelection";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useFormatMultiMachineTimestamp } from "@/utils/TimeHelpers";
import { ReportsTableParser } from "./utils/reports-table-parser";
import { timezoneStore } from "@/store/useTimezoneStore";

export const Reports = () => {
  return (
    <>
      <OverviewNavBar hasTimezone>
        <DateTimeRangePicker />
      </OverviewNavBar>
      <ReportsContent />
    </>
  );
};

export const ReportsContent = () => {
  const translate = useTranslate();
  const { setTimezone, setMachineTimezone } = timezoneStore();
  const [isEnabled, setIsEnabled] = useState(false);
  const { data, isLoading } = useGetReportsList({ isEnabled });
  const { formatMultiMachineTimestamp } = useFormatMultiMachineTimestamp();

  useEffect(() => {
    useTimeSelection.setState((prevState) => ({
      ...prevState,
      dates: {
        dateStart: DateTime.now().minus({ weeks: 1 }).startOf("day"),
        dateEnd: DateTime.now().set({ second: 0, millisecond: 0 }),
      },
    }));
    setIsEnabled(true);
  }, []);

  useEffect(() => {
    setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    setMachineTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, [setTimezone, setMachineTimezone]);

  if (!data?.filesList || isLoading) return <SkeletonCard height={400} />;

  const { columns, rows } =
    data?.filesList.length > 0
      ? ReportsTableParser.parseData({
          data: data?.filesList,
          columnsMap: {
            date: translate("reports.period"),
            entityType: translate("report.entity_type"),
            entity: translate("report.entity"),
            time: translate("timestamp"),
            type: translate("reports.periodicity"),
            filePath: translate("reports.report"),
            name: translate("reports.report"),
            year: translate("reports.report"),
            timestamp: translate("timestamp"),
          },
          columnsOrder: [
            "time",
            "type",
            "date",
            "entity",
            "entityType",
            "filePath",
          ],
          omitColumns: ["name", "year", "timestamp"],
          dateColumns: ["time"],
          customColumns: [
            {
              field: "viewFile",
              headerName: translate("reports.report"),
              renderCell: (params) => {
                const {
                  row: { filePath },
                } = params;
                return (
                  <GridActionsCellItem
                    icon={
                      <StickyNote2OutlinedIcon sx={{ cursor: "pointer" }} />
                    }
                    label="report"
                    onClick={() => {
                      getReportData(filePath);
                    }}
                  />
                );
              },
              flex: 1,
            },
            {
              field: "downloadFile",
              headerName: translate("actions.download"),
              renderCell: (params) => {
                const {
                  row: { filePath },
                } = params;
                return (
                  <GridActionsCellItem
                    icon={
                      <FileDownloadOutlinedIcon sx={{ cursor: "pointer" }} />
                    }
                    label="report"
                    onClick={() => {
                      getDataForDownload(filePath);
                    }}
                  />
                );
              },
              flex: 1,
            },
          ],
          dateFormatter: formatMultiMachineTimestamp,
        })
      : { columns: [], rows: [] };

  const filteredColumns = columns.filter(
    (column) => column.field !== "filePath",
  );

  return (
    <Stack spacing={1}>
      <Typography variant="h5">
        {translate("reports.machines_reports")}
      </Typography>
      <Card>
        <BasicTable
          isMultiMachine
          columns={filteredColumns}
          rows={rows}
          fileName={translate("reports.machines_reports")}
          classes={{ height: 600 }}
        />
      </Card>
    </Stack>
  );
};
