import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { useGetDataSelect } from "../api/useGetDataSelect";
import { colors } from "@/styles/colors";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { useMachineContext } from "@/context/machine-context";
import { ContextInformation } from "@/features/Motors/components/Layouts/ContextInformation";

export const PhysicalAsset = ({ asset }: { asset: string }) => {
  const translate = useTranslate();
  const { data, isLoading } = useGetDataSelect({ physical_name: asset });
  const { machine } = useMachineContext();
  const machineName = machine?.machine;
  const setpointChangesKey = translate("setpoint_changes");

  if (isLoading) return <AnalyticContainerSkeleton />;

  return (
    <>
      {data ? (
        <Card>
          <ContextInformation
            props={{ [setpointChangesKey]: data.setpointChanges }}
          />
          <LineChart.Custom
            filename={fileNameFactory({
              name: "physical_measures",
              machine: machineName,
              date: asset,
            })}
            title={data.name}
            yAxis={[
              {
                uom: "",
                series: [
                  {
                    type: "line",
                    data: data.valueSeries,
                    name: data.name,
                    color: colors.physicalMeasures.actual,
                  },
                  {
                    type: "line",
                    data: data.setpointSeries,
                    name: translate("setpoint"),
                    color: colors.physicalMeasures.setpoint,
                  },
                ],
              },
            ]}
            legend={{ enabled: true }}
            lineType="line"
          />
        </Card>
      ) : null}
    </>
  );
};
